import styled from 'styled-components'
import React from 'react'

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100vw;
	overflow: hidden;
	background-color: #0c0c0c;
	padding: 6em 6em 0 6em;

	@media (max-width: 525px) {
		padding: 2em 2em 0 2em;
	}
`
export const SuccWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 21em;

	.send-wrap {
		display: flex;
	}

	@media (max-width: 1024px) {
		padding-left: 0;
		padding-top: 120px;
		flex-direction: column;
		align-items: flex-start;
	}
`
export const Succ = styled.div`
	font: 65px/90px 'Mont Heavy';
	color: white;

	span {
		margin: 0;
		font: inherit;
		color: inherit;
		margin-right: 15px;
	}

	@media (max-width: 768px) {
		font: 45px/60px 'Mont Heavy';
	}

	@media (max-width: 525px) {
		font-size: 20px;
		line-height: 33px;
	}
`

export const Form = styled.form`
	color: white;
	width: 100%;
	padding-top: 120px;
	padding-bottom: 2em;
	max-width: 140.8em;
	margin: 0 auto;
	text-align: right;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;

	&.inactive {
		display: none;
	}

	span {
		color: white;
		font: 16px/30px 'Poppins Regular';
	}

	textarea {
		font: 40px/55px 'Mont Heavy';
		background-color: transparent;
		border: 0;
		outline: none;
		color: white;
		width: 100%;
		height: calc(100% - 100px);
	}

	button {
		padding: 10px 54px;
		font: 18px/27px 'Poppins Regular';
		background-color: white;
		color: #0c0c0c;
		position: relative;
		text-transform: capitalize;
		transition: opacity 0.25s;
		border-radius: 3px;

		&:hover {
			opacity: 0.7;
		}

		&:disabled {
			pointer-events: none;
			opacity: 0.1;
		}
	}

	label {
		flex: 1;
		/* padding-bottom: 250px; */
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;

		input[type='email'] {
			font: 65px/90px 'Mont Heavy';
			background-color: transparent;
			border: 0;
			outline: none;
			color: white;

			&::placeholder {
				color: #515151;
			}
		}
	}

	@media (max-width: 1024px) {
		margin-left: 0;
		margin-right: 0;

		label {
			justify-content: flex-start;
		}
	}

	@media (max-width: 768px) {
		label input[type='email'] {
			font: 45px/60px 'Mont Heavy';
		}
	}

	@media (max-width: 525px) {
		padding-top: 95px;

		textarea {
			font-size: 20px;
			line-height: 28px;
		}

		button {
			padding: 8px 40px;
			font-size: 14px;
		}

		label input[type='email'] {
			font-size: 20px;
			line-height: 33px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	@media (max-width: 335px) {
		button {
			padding: 5px 30px;
		}
	}
`

export const Privacy = styled.div`
	color: white;
	font: 16px/30px 'Poppins Regular';
	display: flex;
	align-items: center;
	position: relative;

	input {
		margin-right: 25px;
		width: 20px;
		height: 20px;
		appearance: none;
		border: 0;
		outline: none;
		background-color: transparent;
		position: relative;

		&:hover {
			/* cursor: pointer; */
		}

		& + span {
			color: white;

			&:hover {
				/* cursor: pointer; */
			}

			&::before {
				content: '';
				width: 20px;
				height: 20px;
				position: absolute;
				left: 4px;
				bottom: 15px;
				border: 1px solid white;
			}

			&::after {
				content: '';
				width: 6px;
				height: 6px;
				transition: background-color 0.25s ease;
				background-color: transparent;
				position: absolute;
				left: 11px;
				top: 22px;
			}
		}

		&:checked {
			background-color: transparent;
			color: white;

			& + span {
				&::after {
					background-color: white;
				}
			}
		}

		@media (max-width: 525px) {
			margin-right: 15px;
		}
	}

	span,
	a {
		padding: 10px 0;
		font: 16px/30px 'Poppins Regular';

		@media (max-width: 525px) {
			font: 12px/30px 'Poppins Regular';
		}
	}

	span {
		margin: 0;
		margin-right: 5px;
	}

	a {
		text-transform: capitalize;
		text-decoration: underline;
		color: white;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.7;
		}
	}

	@media (max-width: 525px) {
		font-size: 12px;

		span {
			font-size: inherit;
		}
	}
`
export const Email = styled.a`
	color: white;
	text-transform: lowercase;
	font: 20px/30px 'Poppins Regular';
	/* position: absolute; */
	left: 60px;
	bottom: 10px;
	transition: opacity 0.25s;

	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}

	@media (max-width: 525px) {
		left: 20px;
		font-size: 14px;
		padding: 20px 20px 20px 0;
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
