import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Typing from 'react-typing-animation'
import Div100vh from 'react-div-100vh'

// Styles
import { Wrapper, Form, Email, Privacy, SuccWrapper, Succ } from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Contact = React.memo((props) => {
	const inputRef = useRef(null)
	const emailRef = useRef(null)
	const [area, setArea] = useState('Hello timacum, ')
	const [email, setEmail] = useState('')
	const [check, setCheckbox] = useState(false)
	const [emailSection, setEmailSection] = useState(false)
	const [success, setSuccess] = useState(false)
	const [message, setmessage] = useState('')

	useEffect(() => {
		const val = inputRef.current.value
		inputRef.current.value = ''
		inputRef.current.value = val
		// inputRef.current.focus()
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault()

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email: email, text: area }),
		}

		fetch('https://timacum-backend.timacumdev.com/email/send', requestOptions)
			.then(() => {
				setSuccess(true)
			})
			.catch((error) => {
				setSuccess(false)
				console.error('There was an error!', error)
			})
	}

	return (
		<Layout location={props.location} noFooter>
			<SEO
				title={'Contact Timacum'}
				description={'Feel free to get in touch!'}
			/>
			<Wrapper as={Div100vh}>
				<Form
					onSubmit={handleSubmit}
					className={!success ? 'active' : 'inactive'}
				>
					{!emailSection ? (
						<React.Fragment>
							<textarea
								value={area}
								spellCheck={false}
								style={{ resize: 'none' }}
								name="Message"
								onChange={(e) => setArea(e.target.value)}
								ref={inputRef}
								// autoFocus
							/>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Email href="mailto:contact@timacum.com">
									contact@timacum.com
								</Email>

								<button
									type="button"
									onClick={(e) => {
										e.preventDefault()
										setEmailSection(true)
									}}
									aria-label="next"
								>
									next
								</button>
							</div>
						</React.Fragment>
					) : (
						!success && (
							<React.Fragment>
								<label>
									<input
										// autoFocus
										spellCheck={false}
										type="email"
										placeholder="Your email address"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										ref={emailRef}
										required
									/>
									<Privacy>
										<input
											type="checkbox"
											checked={check}
											onChange={() => setCheckbox(!check)}
										/>
										<span
											aria-hidden="true"
											onClick={() => setCheckbox(!check)}
											onKeyDown={() => setCheckbox(!check)}
										>
											I agree to the
										</span>
										<Link to="/privacy-policy/">Privacy Policy</Link>
									</Privacy>
								</label>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Email href="mailto:contact@timacum.com">
										contact@timacum.com
									</Email>
									<button type="submit" aria-label="submit" disabled={!check}>
										send
									</button>
								</div>
							</React.Fragment>
						)
					)}
				</Form>
				{success && (
					<SuccWrapper>
						<div className="send-wrap">
							<Succ>
								<span>Sending email </span>
							</Succ>
							<Succ
								as={Typing}
								speed={500}
								onFinishedTyping={() => setmessage(true)}
							>
								<span>....</span>
							</Succ>
						</div>
						{message !== '' && (
							<Succ>
								<span>SUCCESS!</span>
							</Succ>
						)}
					</SuccWrapper>
				)}
			</Wrapper>
		</Layout>
	)
})

export default Contact
